import { useState } from 'react'
import { uniqBy } from 'lodash'
import { useDebouncedEffect } from './useDebouncedEffect'

const DEFAULT_DEBOUNCE = 500

/**
 *
 * @param searchFn Given a query string returns results that match
 * @param mappingFn Given a raw search result, returns a more convenient shaped result. If not needed, use an identify function
 * @param uniqueKeyFn Given a mapped search result, returns a unique key for that result
 * @param errHandler Handles promise rejections from `searchFn`
 * @param withCache The flag of caching search result or not
 * @param searchKeyFn Given a mapped search result, returns the key that is being searched for filtering purposes
 * @param debounceDuration The duration to wait between calls to `searchFn`
 * @returns { setSearchText, searchResults }
 */
export function useSearchFn<T, MappedResult>(
  searchFn: (q: string) => Promise<{ data: T[] }>,
  mappingFn: (result: T) => MappedResult,
  uniqueKeyFn: (item: MappedResult) => unknown,
  errHandler: (err: unknown) => void,
  withCache: boolean = true,
  searchKeyFn: ((result: MappedResult) => string) | undefined = undefined,
  debounceDuration = DEFAULT_DEBOUNCE
) {
  const [searchText, setSearchText] = useState<string>('')
  const [cachedResults, setCachedResults] = useState<MappedResult[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const searchResults = cachedResults.filter(item => {
    if (searchKeyFn) {
      return searchKeyFn(item).toLowerCase().includes(searchText.toLowerCase())
    } else {
      return item
    }
  })
  useDebouncedEffect(
    async () => {
      setLoading(true)
      const response = await searchFn(searchText).catch(errHandler)
      setLoading(false)
      if (!response) {
        return
      }

      const results = response.data.map(mappingFn)
      const totalRestuls = withCache ? [...cachedResults, ...results] : results
      const uniqueItems = uniqBy(totalRestuls, uniqueKeyFn)
      setCachedResults(uniqueItems)
    },
    [searchText],
    debounceDuration
  )

  return { setSearchText, loading, searchResults }
}

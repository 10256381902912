import React, { useState, forwardRef, Ref } from 'react'
import {
  UseFormRegister,
  UseFormSetValue,
  Path,
  FieldValues,
} from 'react-hook-form'
import SearchAutoComplete from 'components/SearchAutoComplete/SearchAutoComplete'
import {
  SearchAutoCompleteProps,
  Option,
} from 'components/SearchAutoComplete/SearchAutoComplete'
import { BasicRetailer, Retailer } from 'lib/types/retailer'
import { mapRetailer, searchRetailers } from 'lib/forms/admin/requests'
import { DIALOG_CONTENTS } from 'lib/dialogContents'

import { useDialog } from 'hooks/useDialog'
import { useSearchFn } from 'hooks/useSearchFn'
import { countryCodes } from 'lib/forms/shared'

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

async function errHandler(err: unknown) {
  console.log(err)
  throw err
}

type RetailerSelectProps<T extends FieldValues> = Partial<
  SearchAutoCompleteProps<T>
> &
  Partial<Pick<SearchAutoCompleteProps<T>, 'onSelected'>> & {
    controlName: Path<T>
    register: UseFormRegister<T>
    value: Option | Option[]
    setValue: UseFormSetValue<T>
    'data-test-id'?: string
  }

function RetailerSelect<T extends FieldValues>(
  {
    controlName,
    register,
    setValue,
    value,
    error,
    'data-test-id': testId = '',
  }: RetailerSelectProps<T>,
  ref: Ref<HTMLInputElement>
) {
  const [inputText, setInputText] = useState<string | null>('')
  const { showConfirmation } = useDialog()
  const {
    setSearchText,
    loading: searchLoading,
    searchResults,
  } = useSearchFn(
    searchRetailers,
    mapRetailer,
    (r: BasicRetailer) => r.id,
    errHandler,
    true,
    (r: BasicRetailer) => r.backendTitle
  )

  const transformCountryCode = (country: string): string =>
    countryCodes[country] || ''
  const regexTitle = / -\s+New Zealand| -\s+Australia| -\s+NZ| -\s+AU/i
  const regexCountryCode = /New Zealand|Australia/i
  const manipulateSearchTitle = (item: Retailer) => ({
    ...item,
    backendTitle:
      item.backendTitle.replace(regexTitle, '') + ' - ' + item.country,
    title:
      item.backendTitle.replace(regexTitle, '') +
      ' - ' +
      item.country?.replace(regexCountryCode, transformCountryCode),
  })

  const retailerOptions: BasicRetailer[] = value.map(manipulateSearchTitle)
  register(controlName)
  return (
    <SearchAutoComplete
      options={searchResults.map(manipulateSearchTitle)}
      placeholder="Find your Retailers"
      searchTitle="backendTitle"
      inputText={inputText}
      value={retailerOptions}
      multiple={true}
      disabled={false}
      error={error || ''}
      loading={searchLoading}
      onSearch={data => {
        setInputText(data)
        setSearchText(data)
      }}
      onSelected={async (retailers, multiple) => {
        const selectedRetailers = [retailers].flat()
        const removedRetailers = retailerOptions.filter(
          option => !selectedRetailers.includes(option)
        )
        if (removedRetailers.length > 0) {
          // Retailer removed
          const confirmed = await showConfirmation(
            DIALOG_CONTENTS.getRetailerRemovalConfirm(
              removedRetailers[0].backendTitle
            )
          )

          if (!confirmed) {
            return
          }
        }

        multiple
          ? setInputText('')
          : setInputText((retailers as Retailer).backendTitle as string)
        setValue(controlName, selectedRetailers as any)
      }}
      data-test-id={testId}
    />
  )
}

export default forwardRef(RetailerSelect)

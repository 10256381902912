import { BetaUser, CreateBetaUserFormData, EditExistingUserPayload, SignupSuccessProps } from 'lib/types/admin'
import { apiClient, fetcher, postAPI } from 'lib/api'
import { API_URL } from 'lib/config'

import type { RetailerUser, CreateUserForm } from 'lib/types/admin'
import type { ApiResponse, Entry } from 'lib/types/api'
import type { BasicRetailer, RetailerEntry } from 'lib/types/retailer'
import type { User as Auth0User } from 'lib/types/user'

const CREATE_USER_URL = `${API_URL}/users/create`
const CREATE_BETA_USER_URL = `${API_URL}/users/beta/create`

const createRetailerUser = async (user: RetailerUser) => {
  const result = await postAPI(CREATE_USER_URL, user, 'post', true)
  return result
}

const createNewRetailerAndUser = async (user: RetailerUser) => {
  const createUserURL = `${API_URL}/users/createWithRetailer`
  const result = await postAPI(createUserURL, user, 'post', true)
  return result
}

const buildNewRetailer = (data: CreateUserForm) => {
  const { newRetailerName, isNewRetailer, shortDescription, country } = data
  return [
    {
      name: newRetailerName,
      isNewRetailer,
      shortDescription,
      country,
    },
  ]
}

export type SearchRetailerResponse = ApiResponse<Entry<BasicRetailer>[]>

export const mapRetailer = (entry: Entry<RetailerEntry>) => {
  const logoUrl =
    entry?.fields?.retailerLogoSet?.fields?.coloured?.fields?.file?.url
  const regex = /^\/\/images\.ctfassets\.net\//
  const isValidAvatar = regex.test(logoUrl || '')

  return {
    backendTitle: entry?.fields?.backendTitle || '',
    title: entry?.fields?.title || '',
    id: entry?.sys?.id || '',
    country: entry?.fields?.country || '',
    createdAt: entry?.sys?.createdAt || '',
    updatedAt: entry?.sys?.updatedAt || '',
    ...(isValidAvatar && { avatar: `https:${logoUrl}` }),
  }
}

export const fetchInitialRetailers =
  async (): Promise<SearchRetailerResponse> => {
    const url = `${API_URL}/retailers/search`
    return await fetcher(url)
  }

export const searchRetailers = async (
  searchText: string,
): Promise<SearchRetailerResponse> => {
  const url = `${API_URL}/retailers/search?q=${searchText}`
  return await fetcher(url)
}

export async function createWithNewRetailer(
  data: CreateUserForm,
): Promise<SignupSuccessProps> {
  const { firstName, lastName, email } = data
  const retailers = buildNewRetailer(data)
  const payload = {
    firstName,
    lastName,
    email,
    retailers,
  }
  await createNewRetailerAndUser(payload)
  return payload
}

export async function createWithExistingRetailers(
  data: CreateUserForm,
): Promise<SignupSuccessProps | null> {
  const { firstName, lastName, email } = data
  if (data.retailerSelection.length === 0) {
    return null
  }

  const payload = {
    firstName,
    lastName,
    email,
    acceptedTerms: true,
    retailers: data.retailerSelection,
  }
  await createRetailerUser(payload)

  return {
    ...data,
    retailers: data.retailerSelection,
  }
}

export const createBetaUser = async (formData: CreateBetaUserFormData) => {
  const { firstName, lastName, email, accounts, accessLevels } = formData
  const betaUser: BetaUser = {
    firstName,
    lastName,
    email,
    accounts,
    roles: accessLevels,
  }

  return await postAPI(CREATE_BETA_USER_URL, betaUser, 'post', true)
}

export const getUsersByEmail = async (
  email: string,
): Promise<Auth0User[]> => {
  const getUserURL = `${API_URL}/users/user-by-email?email=${email}`
  const { data } = await fetcher<Auth0User[]>(getUserURL, true)
  return data
}

export const searchUsers = async (searchText: string): Promise<any> => {
  const url = `${API_URL}/users/search?q=${encodeURIComponent(searchText)}&pageSize=50&page=0`
  return await fetcher(url)
}

export const updateUser = async (
  id: string,
  payload: EditExistingUserPayload,
) =>
  apiClient({
    url: `/users/${id}`,
    baseURL: API_URL,
    method: 'PUT',
    data: payload,
  })

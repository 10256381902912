import React from 'react'
import { uniqBy } from 'lodash'
import AutoComplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderGetTagProps,
} from '@mui/material/Autocomplete'
import MaterialTextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'

type OptionLabel = { label: string } | { title: string } | { name: string }
export type Option = { [key: string]: any } & OptionLabel & { id: string }
export interface SearchAutoCompleteProps<T> {
  multiple?: boolean
  maxSelections?: number
  options: Option[]
  value?: Option | Option[] | null
  placeholder: string
  disabled: boolean
  searchTitle: string
  inputText: string | null
  onSearch: (search: string) => void
  onSelected: (item: Option | Option[], multiple: boolean) => void
  error: string
  loading: boolean
  'data-test-id'?: string
}

function SearchAutoComplete<T>({
  multiple = false,
  maxSelections = 6,
  options,
  placeholder,
  value = [],
  disabled,
  searchTitle,
  inputText,
  onSearch,
  onSelected,
  error,
  loading,
  'data-test-id': testId = 'search-autocomplete',
}: SearchAutoCompleteProps<T>) {
  const generateInput = (params: AutocompleteRenderInputParams) => (
    <MaterialTextField
      {...params}
      label={placeholder}
      margin="normal"
      variant="outlined"
      type="text"
      error={Boolean(error)}
      helperText={error}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onSearch(e.target?.value)
      }
    />
  )

  const generateTags = (
    tagValues: Option[],
    getTagProps: AutocompleteRenderGetTagProps
  ) =>
    tagValues.map((option, index) => {
      const { key, ...tagProps } = getTagProps({ index })

      return (
        <Chip
          key={`chip${index}`}
          avatar={generateAvatar(option)}
          label={option.title}
          {...tagProps}
        />
      )
    })

  const generateAvatar = (option: Option) => {
    const { title, avatar } = option
    if (avatar) {
      return <Avatar alt="logo" src={avatar} />
    } else {
      return <Avatar>{title.substring(0, 1).toUpperCase()}</Avatar>
    }
  }

  const handleChange = (
    event: React.ChangeEvent<{}>,
    data: Option | Option[] | null
  ) => {
    if (!multiple && data && !Array.isArray(data)) {
      const selectedItem = options.find(
        option => option[searchTitle] === data[searchTitle]
      )
      if (selectedItem) {
        onSelected(selectedItem, multiple)
      }
    } else if (multiple && data && Array.isArray(data)) {
      onSelected(data, multiple)
    } else {
      onSelected([], multiple)
    }
  }

  const deduplicateItems = (
    items: Option[],
    selectedItems: Option[] | Option | null
  ) =>
    uniqBy(items.concat(selectedItems || []), (r: Option) =>
      r.id.toLocaleLowerCase()
    )

  return (
    <AutoComplete
      id="search-autocomplete"
      multiple={multiple}
      options={deduplicateItems(options, value)}
      disabled={disabled}
      loading={loading}
      inputValue={inputText || ''}
      value={value}
      getOptionDisabled={() => value?.length > maxSelections - 1}
      getOptionLabel={option => option[searchTitle] || 'label'}
      isOptionEqualToValue={(option: Option, value: Option) =>
        option.id === value.id
      }
      renderTags={generateTags}
      renderInput={generateInput}
      onChange={(event, data) => handleChange(event, data)}
      data-test-id={testId ? `${testId}-search-auto-complete` : 'auto-complete'}
    />
  )
}

export default SearchAutoComplete
